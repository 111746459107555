import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { PostRequestService } from 'src/app/commons/services/service post request/post-request.service';
import { B2bProductService } from 'src/app/commons/services/service product/b2bservice-service.service';
import { UserService } from 'src/app/commons/services/user.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/commons/shared service/shared-service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-post-request',
  templateUrl: './post-request.component.html',
  styleUrls: ['./post-request.component.css']
})
export class PostRequestComponent implements OnInit,OnDestroy {

  public listCategories = [];
  public selectedCategory = null;
  public selectedProductName = null;
  public listSubCategories = [];
  public selectedCategory2 = [];
  public categoryName = null;
  public selectedSubCategory = null;
  public selectedClassification = null;
  public productToUpdate = null;
  baseUrl = environment.baseUrl+'/';
  public classifications=[];

  public listUnitOfMeasure:any=[];
  public listDeliverTimeFrequency:any=["Monthly","Quarterly","Annually"];
  public orderValues:any=["5000 to 1000","1001 to 5000","5001 to 10000","10001 to 20000","20001 to 50000","50001 to 100000" ,
                          "100001 to 200000", "200001 to 500000" , "More than 500000"];
  public detesToBuy:any=["Immediate","After 1 month"];
  public requirementFrequency = "onetime"
  quantity: any;
  orderValue: any;
  quantityUnit: any;
  deliveryTimeFrequency: any;
  requirementFrequencyValue: any;

  public formPostRequest = new FormGroup({});
  public formData: FormData = new FormData();
  public loading = false;
  public numberUser: any="063565476586754";
  public EmailUser: any;
  public nameUser: any;
  public nameProduct: any;
  public postDescription: any;
  public postID;
  public postIDGet;
  public disable = true;
  previewImage = '';
  previewVisible = false;
  uploadVideo = true;
  public listEmplty = true;
  public videoIsEmpty = true;
  public videoList: UploadFile[] = [];
  public fileList: UploadFile[] = [];

  public video = "";


  constructor( private route: Router,
              private userService: UserService,
              private msg: NzMessageService,
              private requestService: PostRequestService,
              private productService: B2bProductService,
              private fb: FormBuilder
              ,private sharedService: SharedService) { }

  ngOnInit() {
    this.productToUpdate = null;
    this.postIDGet = null;
    this.createFormPost();
    this.getUser();
    this.getCategories();
    this.getAllUnitOfMeasure();
    this.sharedService.Sharedquote.subscribe((resp: any) => {
      console.log("datadatadatadat",resp)
      this.nameProduct = resp.prodName;
      this.quantity = resp.quantity;
      this.quantityUnit = resp.quantityUnit;
      this.postIDGet = resp.postId;
      console.log("datadatadatadat",this.postIDGet)

    });
    this.getPostById();
  }

  ngOnDestroy() {
    // console.log("destroyeeed")
    // this.postIDGet = null;
    // this.productToUpdate = null;
    // console.log("destroyeeed",this.postIDGet)
    // this.formPostRequest.reset();
    // console.log("destroyeeed",this.productToUpdate)
    // console.log("destroyeeed",this.formPostRequest)
    if(this.productToUpdate)
    {
    location.reload();
  }
  }
  getUser() {
    let id = localStorage.getItem("USER_ID")
    if(id)
    {
    this.userService.getAuthUser(id).subscribe(
      (res: any) => {
        this.formPostRequest.get('nameUser').setValue(res.firstName + " " + res.lastName);
        this.formPostRequest.get('EmailUser').setValue(res.email);
        console.log(res);
      })
  }
  }
  getPostById() {
    if(this.postIDGet)
    {
    this.requestService.getById(this.postIDGet).subscribe(
      (res: any) => {
        this.productService.getCatById(res.categoryId).subscribe(
          (res: any) => {
            this.selectedCategory2 = res;
            this.formPostRequest.get('category').setValue(res);
            console.log(res);
          })
        this.productService.getSubCatByname(res.subCategory).subscribe(
          (res: any) => {
            this.selectedProductName = res;
            this.formPostRequest.get('subCategory').setValue(res);
            console.log(res);
          })
          this.productToUpdate = res;
          console.log("to update",this.productToUpdate)
        // this.formPostRequest.get('subCategory').setValue(res.subCategory);
        // this.formPostRequest.get('classification').setValue(res.classification);
        this.selectedClassification = res.classification
        this.formPostRequest.get('productName').setValue(res.productName);
        this.formPostRequest.get('quantity').setValue(res.quantity);
        this.formPostRequest.get('quantityUnit').setValue(res.quantityUnit);
        this.formPostRequest.get('orderValue').setValue(res.approximateOrderValue);
        this.formPostRequest.get('deliveryTimeFrequency').setValue(res.requirementUrgency);
        this.formPostRequest.get('requirementFrequency').setValue(res.requirementFrequency);
        this.formPostRequest.get('requirementFrequencyValue').setValue(res.requirementFrequencyInfo);
        this.formPostRequest.get('postDescription').setValue(res.productDescription);
        console.log("Post value",res);
        console.log("form Post",this.formPostRequest);
      })
  }
  }
  createFormPost() {
    this.formPostRequest = this.fb.group({
      id: [],
      category: ['', Validators.required],
      subCategory: [''],
      classification: [''],
      productName: ['',[ Validators.required,,Validators.pattern('^[a-zA-Z ]*$')]],
      quantity: [0, Validators.required],
      quantityUnit: ['', Validators.required],
      orderValue: ['', Validators.required],
      deliveryTimeFrequency: ['', Validators.required],
      requirementFrequency: ["onetime"],
      requirementFrequencyValue: [''],
      postDescription: ['', Validators.required],
      nameUser: ['', Validators.required],
      EmailUser: ['', Validators.required],
      // numberUser: ['', Validators.required],
      checkboxAgree: [false,  Validators.requiredTrue],
      checkUploadVideo: [''],
      video: ['']
    });
  }
  deleteImg(image){
    let index: number = this.productToUpdate.images.indexOf(image);
    this.productToUpdate.images.splice(index, 1);
  }
  deleteVid(){
    this.productToUpdate.video=null;
  }
  cancel(){
    console.log("canceled")
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
  onChange($event: string[]): void {
    console.log($event);
  }

  getCategories() {
    this.productService.getAllProductCategory().subscribe((resp: any) => {
      console.log(resp);
      this.listCategories = resp
    })
  }
  getSubCategories(event) {
    console.log(this.selectedCategory);
    console.log(event);
    // if(!this.productToUpdate){
    this.selectedCategory2 = event.id
    this.categoryName = event.name
    this.listSubCategories = event.subCategories
    console.log("listSUBBBS",this.listSubCategories);
  // }
}

  addItem(input: HTMLInputElement): void {
    const value = input.value;

    if (this.listCategories.indexOf(value) === -1) {
      this.listCategories = [...this.listCategories, input.value];
    }
    console.log(this.listCategories);

  }

  addItemSubCategories(input: HTMLInputElement): void {
    const value = input.value;
    if (this.listSubCategories.indexOf(value) === -1) {
      this.listSubCategories = [...this.listSubCategories, input.value];
    }
  }



  addPost() {
    if(this.formPostRequest.valid){

    this.loading=true
    let data = {
      categoryId: this.selectedCategory2,
      categoryName: this.categoryName,
      subCategory: this.selectedSubCategory,
      classification : 'classification',
      productName: this.nameProduct,
      productDescription: this.postDescription,
      fileName: "fileName",
      phone: this.numberUser,
      email: this.EmailUser,
      video: this.video,
      name: this.nameUser,
      quantity: this.quantity,
      quantityUnit: this.quantityUnit,
      approximateOrderValue: this.orderValue,
      requirementUrgency: this.deliveryTimeFrequency,
      requirementFrequency: this.requirementFrequency,
      requirementFrequencyInfo: this.requirementFrequencyValue
    }
    console.log('data', data);
    let formData = new FormData();
    if(this.fileList)
    this.fileList.forEach((file: any) => {
      formData.append('files', file);
    });
    if(!this.productToUpdate){

      console.log("datadata",data)
      console.log("datadata",this.productToUpdate)
    this.requestService.addRequest(data).subscribe((resp: any) => {
      console.log("addRequest",resp);
      this.postID=resp.id
      this.requestService.uploadFilesPostByRequest(this.postID,formData).subscribe((resp: any) => {
        console.log(resp);
        this.loading=false;
          this.msg.success('post added successfully');
          this.route.navigate(["/"]);

        if(this.uploadVideo) {

          console.log("video empty",this.videoIsEmpty)
          let fd= new FormData();
          if(!this.videoIsEmpty){
          this.videoList.forEach((file: any) => {
            fd.append('file', file);
          });
          this.requestService.uploadVideoPostByRequest(this.postID,fd).subscribe((resp: any) => {
            console.log(resp);
            this.msg.success('video upload successfully.');
          },(err) => {
            this.loading = false;
            console.log(err);
            this.msg.error('upload video failed.');
          })}
        } else {
          this.loading=false;
          this.msg.success('post added successfully');
          this.route.navigate(["/"]);
        }
      },
      () => {
        this.loading = false;
        this.msg.error('upload failed.');
      })
      },(err) => {
           console.log(err);
        this.msg.error(err.message);
      })
    }else {
      let data1 = {
        id: this.productToUpdate.id,
        creationDate: this.productToUpdate.creationDate,
        country: this.productToUpdate.country,
        flag: this.productToUpdate.flag,
        images: this.productToUpdate.images,
        categoryId: this.postIDGet,
        categoryName: this.productToUpdate.categoryName,
        subCategory: this.productToUpdate.subCategory,
        classification : 'classification',
        productName: this.nameProduct,
        productDescription: this.postDescription,
        fileName: "fileName",
        phone: this.numberUser,
        email: this.EmailUser,
        video: this.productToUpdate.video,
        name: this.nameUser,
        quantity: this.quantity,
        quantityUnit: this.quantityUnit,
        approximateOrderValue: this.orderValue,
        requirementUrgency: this.deliveryTimeFrequency,
        requirementFrequency: this.requirementFrequency,
        requirementFrequencyInfo: this.requirementFrequencyValue
      }
      this.requestService.updateRequest
      (data1).subscribe((resp: any) => {
        console.log("addRequest",resp);
        this.postID=resp.id
        this.requestService.uploadFilesPostByRequest(this.postID,formData).subscribe((resp: any) => {
          console.log(resp);
          this.loading=false;
            this.msg.success('post updated successfully');
            this.route.navigate(["/"]);

          if(!this.videoIsEmpty) {
            let fd= new FormData();
            console.log("video empty",this.videoIsEmpty)
            this.videoList.forEach((file: any) => {
              fd.append('file', file);
            });
            this.requestService.uploadVideoPostByRequest(this.postID,fd).subscribe((resp: any) => {
              console.log(resp);
              this.msg.success('video upload successfully.');
            },(err) => {
              this.loading = false;
              console.log(err);
              this.msg.error('upload video failed.');
            })
          } else {
            this.loading=false;
            this.msg.success('post updated successfully');
            this.route.navigate(["/"]);
          }
        },
        () => {
          this.loading = false;
          this.msg.error('upload failed.');
        })
        },(err) => {
             console.log(err);
          this.msg.error(err.message);
        })
    }
    }
    else{
      this.msg.error('Please fill in all mandatory fields') ;
    }
  }




  getClassification(event) {
    console.log("classification get event : ",event);
    // if(this.productToUpdate){
    this.selectedSubCategory = event.name
    this.classifications = event.classification
  // }
  }

  getAllUnitOfMeasure(){
    this.productService.getUnitOfMeasure().subscribe((resp: any) => {
      console.log(resp);
      let data =resp
      this.listUnitOfMeasure=data.sort();
    })
  }



  beforeUploads = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);

    this.fileList.forEach((file: any) => {
      let data= this.ValidateFile(file);
      if(!data) {
        this.fileList =[]
        this.listEmplty=true;
        return true;
      } else {
        this.listEmplty = false;
      }
    });
    return false;
  };
  handleUploadImages(): void {
    let formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('files', file);
    // let uploadedFiles = this.fileList.map(item => formData.append('file', item, item.name));
    this.requestService.uploadFilesPostByRequest(2, formData).subscribe((resp: any) => {
      console.log(resp);
    })
    });

  }
  changeUploadVideo(value) {
    console.log(value);
    this.uploadVideo = value;
  }

  changeFrequency(value) {
    const requirementFrequencyValue = this.formPostRequest.get('requirementFrequencyValue');
    this.requirementFrequency = value;
    if (this.requirementFrequency === 'one Time') {
      requirementFrequencyValue.setValidators(null);  }
      else  if (this.requirementFrequency === 'regular')  {
        requirementFrequencyValue.setValidators([Validators.required]);
      }
      requirementFrequencyValue.updateValueAndValidity();
    console.log("popopo : " , this.requirementFrequency);

  }


  beforeUploadVideo = (file: UploadFile): boolean => {
    this.videoList = this.videoList.concat(file);
    // this.fileName= file.name
    let data = this.ValidateVideo(file);
    if (!data) {
      this.videoList = [];
      this.videoIsEmpty = true;
    }
    else {
      this.videoIsEmpty = false;
    }
    return false;
  }

  ValidateVideo(file) {
    const isJpgOrPng = file.type === 'video/mp4';
    if (!isJpgOrPng) {
      this.msg.error('Only MP4 files are allowed');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      this.msg.error("the video size should not exceed 25MB! ");
      return false;
    }

    return true
  }

  ValidateFile(file){
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
   if (!isJpgOrPng) {
     this.msg.error('Only JPG or png files are allowed');
    //  this.fileName="Only JPG or png files are allowed";
     return false;
   }
   const isLt2M = file.size / 1024 / 1024 < 10;
   if (!isLt2M) {
     this.msg.error("the image size should not exceed 10MB! ");
    //  this.fileName="the image size should not exceed 10MB! ";
     return false;
   }

   // if(this.fileList.length >=10) {
   //   this.msg.error("Please upload 1 to 10 pictures! ");
   //   this.fileName="Please upload 1 to 10 pictures! ";
   //   return false;
   //  }

   return true;
 }

}
