import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, switchMap, finalize } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { Router } from "@angular/router";
import { AuthProvider } from "../services/auth";
import { UserService } from "../services/user.service";

@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  isRefreshing = false;

  constructor(
    private router: Router,
    private authProvider: AuthProvider,
    private userService: UserService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log(req.url);
    if (
      req.url.endsWith("/api/user/authenticate") ||
      req.url.endsWith("/api/user/refreshtoken") ||
      req.url.endsWith("/api/user/forgotPassword") ||
      req.url.endsWith("/api/user/changepassword") ||

      req.url.includes("/subscription/business") ||
      req.url.includes("/captchaResponse") ||
      req.url.includes("/api/products/getCategories") ||
      req.url.includes("/api/products/getAllSubByCategory") ||
      req.url.endsWith("/api/user/validateEmail") ||

      req.url.endsWith("/api/subscriptions/preferredMarket") ||
      req.url.endsWith("/api/subscriptions/interrestedCategories") ||
      req.url.endsWith("/api/criteria") ||
      (req.url.includes("/api/pricingPlans") && req.method === "GET") ||
      req.url.endsWith("/api/categories") ||
      req.url.endsWith("/api/subscriptions") ||
      req.url.endsWith("/api/products/get_category_home") ||
      req.url.endsWith("/api/products/getAllProductCategory") ||
      req.url.endsWith("/api/postFreightRequests/search") ||
      req.url.endsWith("/api/products/productSearch") ||
      req.url.endsWith("/api/products/unitOfMeasure") ||
      req.url.includes("/api/discount/validate") ||
      req.url.includes("/api/payment") ||

      req.url.endsWith("/api/tradeShows") ||
      // req.url.endsWith("/api/postBuyRequests/getCategories") ||
      req.url.includes("/subscriptions/preferredMarket") ||
      req.url.includes("/subscriptions/interrestedCategories") ||
      req.url.includes("/criteria") ||
      req.url.includes("/categories") ||
      req.url.includes("/banks/activated") ||
      req.url.includes("/products/getCategories") ||
      req.url.includes("/products/getAllProductCategory") ||
      // req.url.includes("/tradeShows") ||
      req.url.includes("/products/get_category_home") ||
      req.url.includes("/products/productSearch") ||
      req.url.includes("/products/productSearch") ||
     // req.url.includes("/postBuyRequests") ||
      req.url.includes("/products/unitOfMeasure") ||
      req.url.includes("/postBuyRequests/getCategories") ||
      req.url.includes("/images/get-display-images")
    )
      return next.handle(req);

    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(environment.TOKEN_NAME)}`,
      },
    });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 402)
          this.router.navigate(["/myProfile/dashboardUser"]);

        if (error.status == 403) this.authProvider.redirectOut();

        if (
          error.status === 401 &&
          error.error.message === "Token JWT expired ou invalid"
        ) {
          if (localStorage.getItem(environment.TOKEN_NAME))
            return this.handle401Error(req, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.userService.refreshToken().pipe(
        switchMap(({ token, refreshToken }) => {
          localStorage.setItem(environment.TOKEN_NAME, token);
          localStorage.setItem(environment.REFRESH_TOKEN_NAME, refreshToken);

          return next.handle(this.setToken(request, token));
        }),
        catchError(() => {
          this.authProvider.redirectOut();
          return throwError("error");
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    }
  }

  private setToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
