import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { KeyFeature } from "src/app/commons/models/KeyFeature";
import { AuthProvider } from "src/app/commons/services/auth";
import { B2bProductService } from "src/app/commons/services/service product/b2bservice-service.service";
import { SubscriptionService } from "src/app/commons/services/subscription/subscription.service";
import { TradeshowsService } from "src/app/commons/services/tradeshows/tradeshows.service";
import { UserService } from "src/app/commons/services/user.service";
import { WebSocketService } from "src/app/commons/services/websocket-service/web-socket.service";
import { SharedService } from "src/app/commons/shared service/shared-service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-header-b2b",
  templateUrl: "./header-b2b.component.html",
  styleUrls: ["./header-b2b.component.css"],
})
export class HeaderB2bComponent implements OnInit, OnDestroy {
  public results1$;
  visible: boolean;
  KeyFeature: KeyFeature;
  position = "leftTop";
  validateForm!: FormGroup;
  validateEmailForm!: FormGroup;
  public isMember = false;
  public visiblecontentTemplate = false;
  public visiblecontentCheck = false;
  isvisible = false;
  isLoggedIn: boolean;
  public firstName = "";
  public lastName = "";
  public country = "";
  public role = null;
  public data: any;
  public isSubs = false;
  public isForgot = false;
  public countMsg: any = {
    countMsgNotSeen: 0,
    countInquiryNotSeen: 0,
    countInterest: 0,
    countOrder: 0,
  };
  public user;
  public isTrad = false;
  public isSubscription = false;
  selectedSearch = "products";
  selectedSearch1 = "name";
  motCle: string = null;
  public baseUrl = environment.baseUrl + "/";
  imgUrl = environment.baseUrl + "/file/aws/download/";
  public formSearch = new FormGroup({});
  listCatgs = [];
  inputValue: string | null = null;

  currentUser: any = null;

  constructor(
    private tradeshowsService: TradeshowsService,
    private _observer: BreakpointObserver,
    private fb: FormBuilder,
    private auth: AuthProvider,
    private router: Router,
    private userService: UserService,
    private toaster: ToastrService,
    private webSocketService: WebSocketService,
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService,
    private b2bService: B2bProductService,
    private msg: NzMessageService
  ) {
    router.events.subscribe((val) => {
      this.checkRout();
      this.checkRout1();
    });
    this.isLoggedIn = auth.isLoggedIn();
    this.currentUser = this.userService.currentUser;
  }
  ngOnInit() {
    console.log("header component");
    console.log("role", this.role);
    this.getAllCatgs();
    this.getCountUser();
    this.getUser();
    this.createForm();
    this.getEventClick();
    this.results1$ = this._observer.observe("(max-width: 767px)");
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.validateEmailForm = this.fb.group({
      email: [null, [Validators.required]],
    });
    this.isLoggedIn = this.auth.isLoggedIn();
    console.log(this.data);
  }

  isSubscriptionActive(): boolean {
    if (this.router.url.includes("/subscription/business")) {
      return true;
    } else {
      return false;
    }
  }
  checkRout() {
    this.isSubs = this.isActive();
  }
  checkRout1() {
    this.isTrad = this.isActive1();
    this.isSubscription = this.isSubscriptionActive();
  }
  createForm() {
    this.formSearch = this.fb.group({
      type: [""],
      name: [""],
    });
  }
  isActive(): boolean {
    if (this.router.url.includes("/subscription")) {
      return true;
    } else {
      return false;
    }
  }
  isActive1(): boolean {
    if (
      this.router.url.includes("/Trade") ||
      this.router.url.includes("/Show") ||
      this.router.url.includes("/subscription/business")
    ) {
      return true;
    } else {
      return false;
    }
  }

  search() {
    if (this.motCle !== null && this.motCle.length > 0) {
      if (this.selectedSearch === "products") {
        console.log("selectedSearch", this.selectedSearch);
        console.log("motCle", this.motCle);
        this.sharedService.nextMessage(this.motCle);
        this.router.navigateByUrl("/search-product");
      } else if (this.selectedSearch === "Companies") {
        this.sharedService.nextMessage(this.motCle);
        this.router.navigateByUrl("/search-company");
      } else if (this.selectedSearch === "services") {
        this.sharedService.nextMessage(this.motCle);
        this.router.navigateByUrl("/search-service");
      }
    }
  }
  signOut(): void {
    if (this.user) {
      this.webSocketService._disconnect(this.user.email);

    }
    this.auth.signOut();
    this.isLoggedIn = this.auth.isLoggedIn();
    this.role = null;
    this.userService.currentUser = null;
    this.userService.userPermissions = null;
    this.router.navigate(["/"]);
    location.reload();
  }

  clickMe1(): void {
    this.isvisible = false;
  }

  getEventClick() {
    this.sharedService.sharedClick.subscribe((resp: any) => {
      this.getUser();
      console.log("useeeeeeeeer");
    });
  }

  onCountReceived() {
    this.webSocketService.onCount(this.user.email);

    this.webSocketService.onCount(this.user.email).subscribe((res) => {
      // alert("connecting to notif")
      this.countMsg = res;
      console.log("new Notification  :", res);
    });
  }

  getUser() {
    const id = localStorage.getItem("USER_ID");
    if (id) {
      this.userService.getUserPermissions(id).subscribe((res: any) => {
        console.log("user result", res);
        this.webSocketService._connect(res.subscription.email);
        this.user = res;
        this.firstName = res.subscription.firstName;
        this.lastName = res.subscription.lastName;
        this.country = res.subscription.country;
        this.role = res.role;
        // setTimeout(() => {
        //   console.log("concnenet");
        //   this.onCountReceived();
        // }, 5000);
      });
    }
  }
  clickMe(): void {
    this.visible = false;
  }
  change(value: boolean): void {
    console.log(value);
  }
  checkMember() {
    this.isMember = true;
  }
  UncheckMember() {
    this.isMember = false;
  }
  showSignup() {
    this.visiblecontentCheck = true;
    console.log("sign up", this.visiblecontentCheck);
  }
  getAllCatgs() {
    this.b2bService.getAllCatgs().subscribe((res: any) => {
      this.listCatgs = res.sort((a, b) => a.id - b.id);
      // this.listCatgs = this.listCatgs.sort(() => 0.10 - Math.random());
    });
  }
  private onDestroy$ = new Subject();
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid)
      this.userService.login(this.validateForm.value)
      .subscribe(
        (res: any) => {
          this.visiblecontentTemplate = false;
          this.currentUser = res;
          this.userService.currentUser = res;

          console.log("current user", this.userService.currentUser);

          console.log("admoooooon", res);
          const token = "token";
          const role = "role";
          const id = "id";
          this.firstName = res.subscription.firstName;
          this.lastName = res.subscription.lastName;
          this.country = res.country;
          this.role = res.role;
          // this.auth.signIn(res[id], res[token], res[role]);

          this.router.navigate(["/"]);
          this.getUser();
          this.validateForm.reset();
          if (res && res[token]) {
            this.auth.signIn(res[id], res[token], res[role], res.refreshToken);
            this.isLoggedIn = this.auth.isLoggedIn();
            if (res[role] == "ROLE_ADMIN") {
              this.router.navigate(["/B2BManagment"]);
            } else {
              console.log("teeest");
              this.userService.editenable = false;
              this.userService
                .getUserPermissions(id)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((permissions: KeyFeature[]) => {
                  console.log(permissions);
                  this.userService.userPermissions = permissions;
                  console.log("permission User",this.userService.userPermissions )
                  this.router.navigate(["/profil/personal"]);
                });
            }
          }
        },
        (err) => {
          console.log(err.error.message);
          if(err.error.message.includes("Login inexist")) {
            this.msg.error("Email or password are incorrect");
          } else {
            this.msg.error("Your account has been disabled by the Administrator");
          }
        }
      );
  }

  goToSubscription(): void {
    this.visiblecontentTemplate = false;

    this.subscriptionService
      .checkTipUser(this.validateForm.value)
      .subscribe((res) => {
        this.sharedService.nextUser(res);
        this.router.navigate(["/subscription/business"]);
      });
  }
  getCountUser() {
    this.subscriptionService.getInfoNotif().subscribe((res) => {
      console.log("info count", res);
      this.countMsg = res;
    });
  }

  goToEmptySubscription(): void {
    this.visiblecontentCheck = false;
    this.sharedService.nextUser(null);
    this.router.navigate(["/subscription/business"]);
  }

  showForgot() {
    this.isForgot = !this.isForgot;
  }

  submitForgottenPassword() {
    for (const i in this.validateEmailForm.controls) {
      this.validateEmailForm.controls[i].markAsDirty();
      this.validateEmailForm.controls[i].updateValueAndValidity();
    }

    if (this.validateEmailForm.valid) {
      this.userService
        .forgotPassword(this.validateEmailForm.value)
        .subscribe((res) => {
          console.log("response : ", res);

          const message = "A new password has been sent to your email";
          this.toaster.success(message, "Success", {
            closeButton: true,
            timeOut: 10000,
          });
        });
    }
  }

  getEventByName(name: string) {
    this.tradeshowsService.getByName(name).subscribe((resp: any) => {
      console.log(resp);
      this.sharedService.nextEvents(resp);
    });
  }
  getEventByCityorCountry(cityorcountry: string) {
    this.tradeshowsService
      .getByCityorCountry(cityorcountry)
      .subscribe((resp: any) => {
        console.log(resp);
        this.sharedService.nextEvents(resp);
      });
  }

  search1() {
    if (this.selectedSearch1 == "name") this.getEventByName(this.inputValue);
    else if (this.selectedSearch1 == "City or Country")
      this.getEventByCityorCountry(this.inputValue);
  }

  handleClear(control: string) {
    (this.validateForm.controls[control] as FormControl).setValue("");
  }

  ngOnDestroy(): void {
    this.webSocketService._disconnect(this.user.subscription.email);
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.currentUser = null;
  }
}
